import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LockOpenOutlined from "@material-ui/icons/LockOpenOutlined";
import { Text } from "@naf/text";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Person from "@material-ui/icons/PersonOutline";
import useRootClose from 'react-overlays/useRootClose'
import { Grid, GridRow, GridCol } from "@naf/grid";
import { User } from "../context/userContext";
import {MasterService} from '../services/MasterService'
import Select from 'react-select';

const Style = styled.div`
  .header-container {
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .naf-logo {
    width: 56px;
    height: 56px;
    border-radius: 4px;
  }
  .logo-text {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px 0px 8px 40px;
    font-size: 20px;
  }
  a:hover {
    color: rgb(38, 124, 79);
    text-decoration: underline;
  }
  a {
    text-decoration: none;
  }
  .header-icons-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
  .icon-button {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
  }
`;
export default function Header(props) {
  const setReset = props.setReset;
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const ref = useRef();
  const [show, setShow] = useState(false);
  const handleRootClose = () => setShow(false);
  const [user] = User();
  const [loading, setLoading] = useState(false);
  const [salesLocations, setSalesLocations] = useState(null);
  const [selectedSalesLocation, setSelectedSalesLocation] = useState(null);
  const [allSalesLocations, setAllSalesLocations] = useState(null);
  useRootClose(ref, handleRootClose, {
    disabled: !show,
  });
  const logoutHandler = () => {
    localStorage.clear()
    instance.logout({
      returnTo: window.location.origin,
    });
  };
  const loginWithRedirect = () => {
    instance.loginRedirect({ scopes: [process.env.REACT_APP_LOGIN_SCOPE] })
  }
  useEffect(() => {
  

    getSalesLocations();
}, [])

useEffect(() => {
  const salesLocation = JSON.parse(localStorage.getItem("salesLocation"))
  if(salesLocation){
  localStorage.setItem("officeLocationNumber", salesLocation?.costCenter);
  }

 setSelectedSalesLocation(salesLocation)
}, [])

  const getSalesLocations = () => {
    const masterService = new MasterService();
      masterService.getSalesLocations().then((res) => {
        setSalesLocations(res.result?.map(item=>  {
          return {
            ...item,
            value:item.id,
            label: `${item.name}`,
          }
        }));

    
      })
  }

  const formatOptionLabel = (data) => (
    <div>
      <span>{`${data.name}-(${data.id}-${data.costCenter})`}</span>
    </div>
  );
  return (
    <Style>
      <Grid>
        <GridRow columns="12">
          <GridCol m="12" l="12" xl="12" >
            <div className="header-container">
              <div className="d-flex  align-items-center h-100">
                <a href="#">
                  <img
                    src="https://www.naf.no/UI/logo.svg"
                    alt="Naf logo"
                    className="naf-logo"
                  />
                </a>
                <a
                  href="tel:+47-08505"
                  className="d-flex flex-direction-column logo-text"
                >
                  <Text
                    style={{
                      margin: 0,
                      lineHeight: "26px",
                    }}
                    tag="span"
                    variant="bodyLarge"
                  >
                    Veihjelp
                  </Text>
                  <div style={{ fontWeight: 700 }}>08505 </div>
                </a>
              </div>


              <div className="header-icons-container">



                {!isAuthenticated && (


                  <div className="icon-button" onClick={loginWithRedirect}>
                    <Text
                      style={{
                        margin: 0,
                      }}
                      tag="span"
                      variant="bodyLarge"
                    >
                      Logg inn
                    </Text>
                    <LockOutlinedIcon />
                  </div>
                )}
               
                {isAuthenticated && accounts.length === 1 && (
                  <div
                  >
                    <div onClick={() => {
                      setShow(true);
                       const val = localStorage.getItem("salesLocation");
                      setSelectedSalesLocation(JSON.parse(val));

                    }} style={{ height: "120px" }} className="d-flex align-items-center col-gap   cusor-pointer">

                      <div>
                        <b>{accounts[0].name} </b>
                        <div>

                         {selectedSalesLocation ?  `${selectedSalesLocation.label}` : user?.officeLocation
                          }
                        </div>
                      </div>
                      <div>
                        < Person />
                      </div>
                    </div>
                    {show && (
                      <div
                        ref={ref}
                        className="rounded shadow  p-1 d-flex flex-direction-column"
                        style={{ position: 'absolute', margin: "-2em" }}
                      >
                        {/* <div  style={{borderBottom: "1px solid #c1c1c1", padding: "0.5em"}}>
                          {user?.officeLocation}
                        </div> */}
                        {
                          
                          salesLocations && <Select   isSearchable={true}  options={salesLocations}
                          onChange={(val)=>{
                            setSelectedSalesLocation(val);
                            localStorage.setItem("officeLocationNumber", val?.costCenter);
                            localStorage.setItem("salesLocation",JSON.stringify(val))}}
                           defaultValue={selectedSalesLocation}

                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            
                            colors: {
                              ...theme.colors,
                              primary25: '#FFF0B8',
                              primary: '#FFDB16',
                            },
                          })}
                           /> 
                        }
                     {/* {  salesLocations &&  <Search  maxWidth={400} withButton={false}  handleSubmit={e => console.log('normal search: ', e)} handleChange={(searchtext) => {
      setLoading(true);
      let timeout;
      clearTimeout(timeout);
      setSalesLocations(allSalesLocations.filter(item => item.name.includes(searchtext) ))
      timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);
    }}>
            <SearchList loading={loading}>
              {salesLocations.map(s => <SearchItem key={s.id} label={`${s.name}-(${s.id}-${s.costCenter})`}  value={"83090014"} handleClick={a => {
                localStorage.setItem("salesLocation",JSON.stringify(a))
              }} />)}
            </SearchList>
          </Search>} */}
                        <div style={{ borderBottom: "1px solid #c1c1c1", padding: "0.5em" }}>
                          {accounts[0].username}
                        </div>


                        <div style={{ padding: "0.5em" }} className="cusor-pointer" onClick={logoutHandler}>
                          Logg ut
                        </div>

                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </GridCol>

        </GridRow>
      </Grid>



    </Style>
  );
}
